<template lang="pug">
  include ../plugins/pug/btn

  section.section.spacer-xl
    div.container
      div.page__header
        div.page__header-top
          div.page__header-img
            img(:src="headerImg" alt="wall decor")
          h1.h1.page__header-title.uppercase.color-orange Our <strong>{{ headerTitle }}</strong>
        div.page__header-content.text-xl {{ headerContent }}
</template>

<script>
// images
import Wall from '@/assets/icons/wall-icon.svg'
export default {
  name: 'PageHeaderBlock',
  props: {
    headerImg: {
      type: String,
      default: Wall
    },
    headerTitle: {
      type: String,
      required: true,
      default: ''
    },
    headerContent: {
      type: String,
      required: true,
      default: ''
    }
  }
}
</script>
